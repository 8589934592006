import Vue from 'vue';
import Component from 'vue-class-component';
import Navbar from '@/components/common/navbar/Navbar.vue';
import Footer from '@/components/common/footer/Footer.vue';
import BackToTop from '@/components/common/back-to-top/BackToTop.component.vue';
import { gdprPermissions } from '@/gdprTrackers';
import GdprConsentModal from '@/components/gdpr/gdprConsentModal/GdprConsentModal.vue';
import { Action } from 'vuex-class';

@Component({
  components: {
    Navbar,
    Footer,
    BackToTop,
    GdprConsent: GdprConsentModal,
  },
  metaInfo: {
    title: 'Privacyverklaring',
  },
})

export default class Privacy extends Vue {
  gdprPermissions = gdprPermissions;

  @Action showGDPR!: Function;

  get brand(): string {
    return require('../../../whitelabel.config').name;
  }

  openGdpr(): void {
    this.showGDPR(true);
  }
}
